.jobOfferForm {
  .joDescEditor {
    .HTMLEditorContent {
      font-family: HelveticaNeueLTPro, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
        Ubuntu, Cantarell;
    }
  }

  .ql-editor {
    line-height: 25px;

    hr {
      width: 40%;
      max-width: 100%;
      margin: 2em 0;
      min-width: 200px;
      border: 0;
      height: 1px;
      background-color: #d3d3d3;
    }

    ul {
      list-style: disc;
      padding-left: 1em;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      margin-block-start: 0.83em !important;
      margin-block-end: 0.83em !important;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em !important;
      margin-block-end: 1em !important;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }

    p {
      margin: 0;
    }

    span.hlv {
      color: #1890ff;
    }
  }
}
