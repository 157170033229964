.quill {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 16px;
  outline: none;
  min-height: 200px;
}

.ql-editor {
  outline: none;
  flex-grow: 1;
  overflow: auto;
}

.HTMLEditorContent {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  ul {
    list-style: disc;
    padding-left: 1em;
  }

  ol {
    list-style: decimal;
    padding-left: 1em;
  }

  > div {
    padding: 8px;
    flex-grow: 1;
    margin-bottom: 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #d9d9d9;

    &:focus {
      border: 1px solid rgb(161, 161, 161);
    }
  }
}

.HTMLEditorToolbar {
  margin-bottom: 8px;

  > div {
    min-width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.is-active {
      background-color: #1890ff;
      color: white;
    }
  }
}

.HTMLEditorVars {
  position: relative;

  .HTMLEditorVarMenu {
    position: absolute;
    top: 2em;
    text-align: left;
    background-color: #fff;
    min-width: 15em;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    border: 1px solid #d9d9d9;
    z-index: 5;
    display: flex;
    padding: 8px;

    .varMenu {
      border-right: 1px solid #d9d9d9;
      padding: 0 8px;

      &:last-child {
        border-right: none;
      }

      &Title {
        font-weight: bold;
        padding: 8px;
        white-space: nowrap;
      }

      &Elem {
        padding: 8px;
        white-space: nowrap;

        &:hover {
          // background-color: lighten($theme, 5%);
          color: #40a9ff;
        }
      }
    }
  }
}
