@import (css) url("../../node_modules/@getprorecrutement/getpro-design/dist/getpro-design.cjs.production.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./app.less";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: Montserrat, arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}
