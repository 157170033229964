.DashboardCustomTooltip {
  border-radius: 10px;
  background-color: rgb(240, 240, 240);
  padding: 12px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05) !important;

  &Date {
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &Data {
    margin-bottom: 4px;
    padding: 2px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    color: white;
  }

  &Label {
    margin-right: 12px;
    font-size: 16px;
  }

  &Value {
    font-weight: bold;
  }
}
