@import "getpro-design.cjs.production.min.186ca966.css";

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-left-\[16px\] {
  left: -16px;
}

.-right-2 {
  right: -.5rem;
}

.-right-2\.5 {
  right: -.625rem;
}

.-right-8 {
  right: -2rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-4 {
  top: -1rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-\[15px\] {
  top: -15px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-\[calc\(100\%\)\] {
  bottom: 100%;
}

.bottom-\[calc\(100\%\+12px\)\] {
  bottom: calc(100% + 12px);
}

.bottom-\[calc\(100\%\+6px\)\] {
  bottom: calc(100% + 6px);
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/4 {
  left: 25%;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-6 {
  left: 1.5rem;
}

.left-8 {
  left: 2rem;
}

.left-9 {
  left: 2.25rem;
}

.left-\[-25\%\] {
  left: -25%;
}

.left-\[-40\%\] {
  left: -40%;
}

.left-\[228px\] {
  left: 228px;
}

.left-\[25\%\] {
  left: 25%;
}

.left-\[40\%\] {
  left: 40%;
}

.left-\[calc\(100\%\+12px\)\] {
  left: calc(100% + 12px);
}

.left-\[calc\(100\%\+4px\)\] {
  left: calc(100% + 4px);
}

.left-\[calc\(100\%\+6px\)\] {
  left: calc(100% + 6px);
}

.left-\[calc\(50\%\+28px\)\] {
  left: calc(50% + 28px);
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-1\/4 {
  right: 25%;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.right-8 {
  right: 2rem;
}

.right-\[calc\(100\%\+12px\)\] {
  right: calc(100% + 12px);
}

.right-\[calc\(100\%\+6px\)\] {
  right: calc(100% + 6px);
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-\[0\%\] {
  top: 0%;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[100\%\] {
  top: 100%;
}

.top-\[13\.5px\] {
  top: 13.5px;
}

.top-\[3px\] {
  top: 3px;
}

.top-\[90\%\] {
  top: 90%;
}

.top-\[calc\(100\%\)\] {
  top: 100%;
}

.top-\[calc\(100\%\+12px\)\] {
  top: calc(100% + 12px);
}

.top-\[calc\(100\%\+6px\)\] {
  top: calc(100% + 6px);
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1071\] {
  z-index: 1071;
}

.z-\[500\] {
  z-index: 500;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-4\.5 {
  height: 18px;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[10\%\] {
  height: 10%;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[200\%\] {
  height: 200%;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[900px\] {
  height: 900px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-\[16px\] {
  max-height: 16px;
}

.max-h-\[220px\] {
  max-height: 220px;
}

.max-h-\[24px\] {
  max-height: 24px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[32px\] {
  max-height: 32px;
}

.max-h-\[60px\] {
  max-height: 60px;
}

.max-h-\[80\%\] {
  max-height: 80%;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[16px\] {
  min-height: 16px;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[50px\] {
  min-height: 50px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.min-h-\[8px\] {
  min-height: 8px;
}

.min-h-fit {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\.5 {
  width: 18px;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[\"\+b\+\"px\] {
  width: "+b+"px;
}

.w-\[\"\+k\+\"px\] {
  width: "+k+"px;
}

.w-\[10\%\] {
  width: 10%;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[140\%\] {
  width: 140%;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[165px\] {
  width: 165px;
}

.w-\[190px\] {
  width: 190px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[200\%\] {
  width: 200%;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[32\%\] {
  width: 32%;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[4px\] {
  width: 4px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[630px\] {
  width: 630px;
}

.w-\[65\%\] {
  width: 65%;
}

.w-\[800px\] {
  width: 800px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[928px\] {
  width: 928px;
}

.w-\[calc\(100\%-32px\)\] {
  width: calc(100% - 32px);
}

.w-\[calc\(100\%-48px\)\] {
  width: calc(100% - 48px);
}

.w-\[calc\(100\%-56px\)\] {
  width: calc(100% - 56px);
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-modal-lg {
  width: 860px;
}

.w-modal-md {
  width: 680px;
}

.w-modal-sm {
  width: 500px;
}

.w-modal-xl {
  width: 1040px;
}

.w-modal-xs {
  width: 320px;
}

.w-screen {
  width: 100vw;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[12px\] {
  min-width: 12px;
}

.min-w-\[140px\] {
  min-width: 140px;
}

.min-w-\[14px\] {
  min-width: 14px;
}

.min-w-\[16px\] {
  min-width: 16px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[20px\] {
  min-width: 20px;
}

.min-w-\[24px\] {
  min-width: 24px;
}

.min-w-\[250px\] {
  min-width: 250px;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-\[320px\] {
  min-width: 320px;
}

.min-w-\[32px\] {
  min-width: 32px;
}

.min-w-\[400px\] {
  min-width: 400px;
}

.min-w-\[40px\] {
  min-width: 40px;
}

.min-w-\[48px\] {
  min-width: 48px;
}

.min-w-\[64px\] {
  min-width: 64px;
}

.min-w-\[8px\] {
  min-width: 8px;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.min-w-max {
  min-width: max-content;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[110px\] {
  max-width: 110px;
}

.max-w-\[1400px\] {
  max-width: 1400px;
}

.max-w-\[16px\] {
  max-width: 16px;
}

.max-w-\[240px\] {
  max-width: 240px;
}

.max-w-\[24px\] {
  max-width: 24px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[32px\] {
  max-width: 32px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[95\%\] {
  max-width: 95%;
}

.max-w-\[calc\(100\%-128px\)\] {
  max-width: calc(100% - 128px);
}

.max-w-\[calc\(100\%-18px\)\] {
  max-width: calc(100% - 18px);
}

.max-w-\[calc\(100\%-22px\)\] {
  max-width: calc(100% - 22px);
}

.max-w-\[calc\(100\%-24px\)\] {
  max-width: calc(100% - 24px);
}

.max-w-\[calc\(100\%-400px\)\] {
  max-width: calc(100% - 400px);
}

.max-w-\[calc\(100\%-56px\)\] {
  max-width: calc(100% - 56px);
}

.max-w-\[calc\(100\%-72px\)\] {
  max-width: calc(100% - 72px);
}

.max-w-\[calc\(66\%-24px\)\] {
  max-width: calc(66% - 24px);
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.basis-full {
  flex-basis: 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2 {
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-2\.5 {
  --tw-translate-x: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[32px\] {
  --tw-translate-x: -32px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[calc\(100\%-48px\)\] {
  --tw-translate-x: calc(calc(100% - 48px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[calc\(50\%-6px\)\] {
  --tw-translate-x: calc(calc(50% - 6px) * -1);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2\.5 {
  --tw-translate-x: .625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.\!rounded {
  border-radius: .25rem !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-4xl {
  border-radius: 28px;
}

.rounded-\[33\%\] {
  border-radius: 33%;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#91d5ff\] {
  --tw-border-opacity: 1;
  border-color: rgb(145 213 255 / var(--tw-border-opacity));
}

.border-\[\#b7eb8f\] {
  --tw-border-opacity: 1;
  border-color: rgb(183 235 143 / var(--tw-border-opacity));
}

.border-\[\#d3adf7\] {
  --tw-border-opacity: 1;
  border-color: rgb(211 173 247 / var(--tw-border-opacity));
}

.border-\[\#ffbb96\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 187 150 / var(--tw-border-opacity));
}

.border-\[\#ffd591\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 213 145 / var(--tw-border-opacity));
}

.border-\[\#ffe58f\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 229 143 / var(--tw-border-opacity));
}

.border-background-lightest {
  --tw-border-opacity: 1;
  border-color: rgb(243 242 249 / var(--tw-border-opacity));
}

.border-border-bright {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(27 40 77 / var(--tw-border-opacity));
}

.border-border-darker {
  --tw-border-opacity: 1;
  border-color: rgb(18 27 62 / var(--tw-border-opacity));
}

.border-border-light {
  --tw-border-opacity: 1;
  border-color: rgb(95 105 145 / var(--tw-border-opacity));
}

.border-border-lighter {
  --tw-border-opacity: 1;
  border-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.border-border-lightest {
  --tw-border-opacity: 1;
  border-color: rgb(219 222 236 / var(--tw-border-opacity));
}

.border-border-medium {
  --tw-border-opacity: 1;
  border-color: rgb(45 64 109 / var(--tw-border-opacity));
}

.border-content-darker {
  --tw-border-opacity: 1;
  border-color: rgb(9 20 49 / var(--tw-border-opacity));
}

.border-content-lighter {
  --tw-border-opacity: 1;
  border-color: rgb(228 232 239 / var(--tw-border-opacity));
}

.border-content-medium {
  --tw-border-opacity: 1;
  border-color: rgb(82 116 164 / var(--tw-border-opacity));
}

.border-content-regular {
  --tw-border-opacity: 1;
  border-color: rgb(117 146 189 / var(--tw-border-opacity));
}

.border-error-dark {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-error-light {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.border-error-normal {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.border-primary-bright {
  --tw-border-opacity: 1;
  border-color: rgb(234 229 252 / var(--tw-border-opacity));
}

.border-primary-darker {
  --tw-border-opacity: 1;
  border-color: rgb(38 21 104 / var(--tw-border-opacity));
}

.border-primary-light {
  --tw-border-opacity: 1;
  border-color: rgb(143 104 252 / var(--tw-border-opacity));
}

.border-primary-lighter {
  --tw-border-opacity: 1;
  border-color: rgb(177 157 255 / var(--tw-border-opacity));
}

.border-primary-medium {
  --tw-border-opacity: 1;
  border-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-success-light {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-warning-light {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.border-b-border-lighter {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.border-b-border-regular {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.border-b-primary-light {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(143 104 252 / var(--tw-border-opacity));
}

.border-b-primary-lighter {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(177 157 255 / var(--tw-border-opacity));
}

.border-b-primary-medium {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-l-border-lighter {
  --tw-border-opacity: 1;
  border-left-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.border-l-border-regular {
  --tw-border-opacity: 1;
  border-left-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.border-r-border-lighter {
  --tw-border-opacity: 1;
  border-right-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.border-r-border-regular {
  --tw-border-opacity: 1;
  border-right-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.border-t-border-lighter {
  --tw-border-opacity: 1;
  border-top-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.border-t-border-regular {
  --tw-border-opacity: 1;
  border-top-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.bg-\[\"\+m\+\"\] {
  background-color: "+m+";
}

.bg-\[\#000000\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-\[\#115290\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 82 144 / var(--tw-bg-opacity));
}

.bg-\[\#1890ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(24 144 255 / var(--tw-bg-opacity));
}

.bg-\[\#8de879\] {
  --tw-bg-opacity: 1;
  background-color: rgb(141 232 121 / var(--tw-bg-opacity));
}

.bg-\[\#F4F4F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-\[\#cbba82\] {
  --tw-bg-opacity: 1;
  background-color: rgb(203 186 130 / var(--tw-bg-opacity));
}

.bg-\[\#e6f7ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 247 255 / var(--tw-bg-opacity));
}

.bg-\[\#ededed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 237 / var(--tw-bg-opacity));
}

.bg-\[\#f6ffed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity));
}

.bg-\[\#f9f0ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 240 255 / var(--tw-bg-opacity));
}

.bg-\[\#fff2e8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 242 232 / var(--tw-bg-opacity));
}

.bg-\[\#fff7e6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 230 / var(--tw-bg-opacity));
}

.bg-\[\#fffbe6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 230 / var(--tw-bg-opacity));
}

.bg-background-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 253 / var(--tw-bg-opacity));
}

.bg-background-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(12 25 58 / var(--tw-bg-opacity));
}

.bg-background-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(2 13 41 / var(--tw-bg-opacity));
}

.bg-background-light {
  --tw-bg-opacity: 1;
  background-color: rgb(177 174 193 / var(--tw-bg-opacity));
}

.bg-background-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(236 234 243 / var(--tw-bg-opacity));
}

.bg-background-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 249 / var(--tw-bg-opacity));
}

.bg-background-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(24 34 77 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-border-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-border-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(27 40 77 / var(--tw-bg-opacity));
}

.bg-content-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(248 247 253 / var(--tw-bg-opacity));
}

.bg-content-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(45 55 97 / var(--tw-bg-opacity));
}

.bg-content-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(9 20 49 / var(--tw-bg-opacity));
}

.bg-content-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(239 241 246 / var(--tw-bg-opacity));
}

.bg-content-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(82 116 164 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-error-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 225 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-primary-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(234 229 252 / var(--tw-bg-opacity));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(79 36 171 / var(--tw-bg-opacity));
}

.bg-primary-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(38 21 104 / var(--tw-bg-opacity));
}

.bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(143 104 252 / var(--tw-bg-opacity));
}

.bg-primary-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(177 157 255 / var(--tw-bg-opacity));
}

.bg-primary-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(216 206 254 / var(--tw-bg-opacity));
}

.bg-primary-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(121 66 237 / var(--tw-bg-opacity));
}

.bg-primary-medium\/10 {
  background-color: #7942ed1a;
}

.bg-primary-regular {
  --tw-bg-opacity: 1;
  background-color: rgb(131 89 246 / var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-success-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.stroke-content-light {
  stroke: #bbc9da;
}

.stroke-content-lighter {
  stroke: #e4e8ef;
}

.stroke-content-medium {
  stroke: #5274a4;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.object-contain {
  object-fit: contain;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[16\%\] {
  padding: 16%;
}

.p-\[3px\] {
  padding: 3px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px;
}

.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.py-\[9px\] {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[0\.325em\] {
  font-size: .325em;
}

.text-\[0\.7em\] {
  font-size: .7em;
}

.text-\[0px\] {
  font-size: 0;
}

.text-\[1\.5em\] {
  font-size: 1.5em;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[1em\] {
  font-size: 1em;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.\!text-white\/0 {
  color: #fff0 !important;
}

.text-\[\#096dd9\] {
  --tw-text-opacity: 1;
  color: rgb(9 109 217 / var(--tw-text-opacity));
}

.text-\[\#1890ff\] {
  --tw-text-opacity: 1;
  color: rgb(24 144 255 / var(--tw-text-opacity));
}

.text-\[\#33B172\] {
  --tw-text-opacity: 1;
  color: rgb(51 177 114 / var(--tw-text-opacity));
}

.text-\[\#389e0d\] {
  --tw-text-opacity: 1;
  color: rgb(56 158 13 / var(--tw-text-opacity));
}

.text-\[\#531dab\] {
  --tw-text-opacity: 1;
  color: rgb(83 29 171 / var(--tw-text-opacity));
}

.text-\[\#8c8c8c\] {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.text-\[\#a1a1a1\] {
  --tw-text-opacity: 1;
  color: rgb(161 161 161 / var(--tw-text-opacity));
}

.text-\[\#b1b1b1\] {
  --tw-text-opacity: 1;
  color: rgb(177 177 177 / var(--tw-text-opacity));
}

.text-\[\#d4380d\] {
  --tw-text-opacity: 1;
  color: rgb(212 56 13 / var(--tw-text-opacity));
}

.text-\[\#d46b08\] {
  --tw-text-opacity: 1;
  color: rgb(212 107 8 / var(--tw-text-opacity));
}

.text-\[\#d48806\] {
  --tw-text-opacity: 1;
  color: rgb(212 136 6 / var(--tw-text-opacity));
}

.text-background-bright {
  --tw-text-opacity: 1;
  color: rgb(248 248 253 / var(--tw-text-opacity));
}

.text-background-dark {
  --tw-text-opacity: 1;
  color: rgb(12 25 58 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-content-bright {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.text-content-dark {
  --tw-text-opacity: 1;
  color: rgb(45 55 97 / var(--tw-text-opacity));
}

.text-content-darker {
  --tw-text-opacity: 1;
  color: rgb(9 20 49 / var(--tw-text-opacity));
}

.text-content-light {
  --tw-text-opacity: 1;
  color: rgb(187 201 218 / var(--tw-text-opacity));
}

.text-content-lighter {
  --tw-text-opacity: 1;
  color: rgb(228 232 239 / var(--tw-text-opacity));
}

.text-content-medium {
  --tw-text-opacity: 1;
  color: rgb(82 116 164 / var(--tw-text-opacity));
}

.text-content-regular {
  --tw-text-opacity: 1;
  color: rgb(117 146 189 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-error-dark {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-error-darker {
  --tw-text-opacity: 1;
  color: rgb(105 41 52 / var(--tw-text-opacity));
}

.text-error-light {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.text-error-normal {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-primary-bright {
  --tw-text-opacity: 1;
  color: rgb(234 229 252 / var(--tw-text-opacity));
}

.text-primary-dark {
  --tw-text-opacity: 1;
  color: rgb(79 36 171 / var(--tw-text-opacity));
}

.text-primary-darker {
  --tw-text-opacity: 1;
  color: rgb(38 21 104 / var(--tw-text-opacity));
}

.text-primary-light {
  --tw-text-opacity: 1;
  color: rgb(143 104 252 / var(--tw-text-opacity));
}

.text-primary-lighter {
  --tw-text-opacity: 1;
  color: rgb(177 157 255 / var(--tw-text-opacity));
}

.text-primary-lightest {
  --tw-text-opacity: 1;
  color: rgb(216 206 254 / var(--tw-text-opacity));
}

.text-primary-medium {
  --tw-text-opacity: 1;
  color: rgb(121 66 237 / var(--tw-text-opacity));
}

.text-primary-regular {
  --tw-text-opacity: 1;
  color: rgb(131 89 246 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-success-dark {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.text-warning-dark {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-\[\#33B172\] {
  text-decoration-color: #33b172;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-background-darker {
  --tw-shadow-color: #020d29;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-background-light {
  --tw-shadow-color: #b1aec1;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[margin\] {
  transition-property: margin;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a, a:hover {
  color: unset;
}

.companyLogo {
  max-width: 100px;
  max-height: 60px;
}

.locked, .blacklisted {
  box-shadow: inset 5px 0 #c4202080;
}

.CandidateLink {
  color: #1890ff;
}

::-webkit-scrollbar {
  background-color: #0000;
}

::-webkit-scrollbar:vertical {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-corner {
  background-color: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 1em;
}

.login {
  height: 100%;
  background-color: #2c76ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%231508ff' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%232c76ff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%232c71f8' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%232c6cf1' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%232c67ea' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%232c62e3' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%232c5ddc' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%232b58d5' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%232a53ce' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23294ec7' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23284ac0' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%232745b9' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%232540b3' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23243cac' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%232237a5' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%2320339f' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%231e2e98' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%231c2991' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%231a258b' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media print {
  .dashboardHeader {
    display: none;
  }

  .dashboardModule {
    page-break-before: auto;
  }
}

.supersetDashboard, .supersetDashboard iframe {
  height: 100vh;
  width: 100%;
}

.loaderPage {
  text-align: center;
  height: 100%;
  background: #999 radial-gradient(circle farthest-side, #333, #111);
}

.loader {
  width: 7.33333em;
  height: 7.33333em;
  margin-top: -3.66667em;
  margin-left: -3.66667em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loader-block {
  opacity: 0;
  width: 2em;
  height: 2em;
  background: #fdfdfd;
  animation: .4s step-end infinite alternate show, .4s linear infinite alternate pulse;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-block:nth-child(1) {
  animation-delay: 30ms;
  transform: translate(0);
}

.loader-block:nth-child(2) {
  animation-delay: 60ms;
  transform: translate(2.66667em);
}

.loader-block:nth-child(3) {
  animation-delay: 90ms;
  transform: translate(5.33333em);
}

.loader-block:nth-child(4) {
  animation-delay: .15s;
  transform: translate(0, 2.66667em);
}

.loader-block:nth-child(5) {
  animation-delay: 60ms;
  transform: translate(2.66667em, 2.66667em);
}

.loader-block:nth-child(6) {
  animation-delay: .21s;
  transform: translate(5.33333em, 2.66667em);
}

.loader-block:nth-child(7) {
  animation-delay: .18s;
  transform: translate(0, 5.33333em);
}

.loader-block:nth-child(8) {
  animation-delay: .12s;
  transform: translate(2.66667em, 5.33333em);
}

.loader-block:nth-child(9) {
  transform: translate(5.33333em, 5.33333em);
}

@keyframes pulse {
  from, 40% {
    background: #fdfdfd;
  }

  to {
    background: #dadada;
  }
}

@keyframes show {
  from, 40% {
    opacity: 0;
  }

  41%, to {
    opacity: 1;
  }
}

.jobOfferForm .joDescEditor .HTMLEditorContent {
  font-family: HelveticaNeueLTPro, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell;
}

.jobOfferForm .ql-editor {
  line-height: 25px;
}

.jobOfferForm .ql-editor hr {
  width: 40%;
  max-width: 100%;
  min-width: 200px;
  height: 1px;
  background-color: #d3d3d3;
  border: 0;
  margin: 2em 0;
}

.jobOfferForm .ql-editor ul {
  padding-left: 1em;
  list-style: disc;
}

.jobOfferForm .ql-editor h2 {
  margin-inline: 0;
  font-size: 1.5em;
  font-weight: bold;
  display: block;
  margin-block: .83em !important;
}

.jobOfferForm .ql-editor h3 {
  margin-inline: 0;
  font-size: 1.17em;
  font-weight: bold;
  display: block;
  margin-block: 1em !important;
}

.jobOfferForm .ql-editor p {
  margin: 0;
}

.jobOfferForm .ql-editor span.hlv {
  color: #1890ff;
}

.quill {
  min-height: 200px;
  background-color: #fff;
  outline: none;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 16px;
  display: flex;
}

.ql-editor {
  outline: none;
  flex-grow: 1;
  overflow: auto;
}

.HTMLEditorContent h1 {
  font-size: 2em;
}

.HTMLEditorContent h2 {
  font-size: 1.5em;
}

.HTMLEditorContent h3 {
  font-size: 1.17em;
}

.HTMLEditorContent ul {
  padding-left: 1em;
  list-style: disc;
}

.HTMLEditorContent ol {
  padding-left: 1em;
  list-style: decimal;
}

.HTMLEditorContent > div {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  outline: none;
  flex-grow: 1;
  margin-bottom: 12px;
  padding: 8px;
}

.HTMLEditorContent > div:focus {
  border: 1px solid #a1a1a1;
}

.HTMLEditorToolbar {
  margin-bottom: 8px;
}

.HTMLEditorToolbar > div {
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.HTMLEditorToolbar > div.is-active {
  color: #fff;
  background-color: #1890ff;
}

.HTMLEditorVars {
  position: relative;
}

.HTMLEditorVars .HTMLEditorVarMenu {
  text-align: left;
  min-width: 15em;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px;
  display: flex;
  position: absolute;
  top: 2em;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.HTMLEditorVars .HTMLEditorVarMenu .varMenu {
  border-right: 1px solid #d9d9d9;
  padding: 0 8px;
}

.HTMLEditorVars .HTMLEditorVarMenu .varMenu:last-child {
  border-right: none;
}

.HTMLEditorVars .HTMLEditorVarMenu .varMenuTitle {
  white-space: nowrap;
  padding: 8px;
  font-weight: bold;
}

.HTMLEditorVars .HTMLEditorVarMenu .varMenuElem {
  white-space: nowrap;
  padding: 8px;
}

.HTMLEditorVars .HTMLEditorVarMenu .varMenuElem:hover {
  color: #40a9ff;
}

.userStatus .notif a {
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
}

.userStatus .notif span {
  color: #1890ff;
  margin-right: 8px;
  font-size: 20px;
  font-weight: bold;
}

.userStatus .assignation a {
  color: #1890ff;
  margin: 0 5px;
}

.DashboardCustomTooltip {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0 20px 27px #0000000d !important;
}

.DashboardCustomTooltipDate {
  justify-content: space-evenly;
  margin-bottom: 8px;
  font-weight: bold;
  display: flex;
}

.DashboardCustomTooltipData {
  color: #fff;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 2px 12px;
  display: flex;
}

.DashboardCustomTooltipLabel {
  margin-right: 12px;
  font-size: 16px;
}

.DashboardCustomTooltipValue {
  font-weight: bold;
}

.ribbon {
  width: 150px;
  height: 150px;
  z-index: 1059;
  position: absolute;
  overflow: hidden;
}

.ribbon:before, .ribbon:after {
  z-index: -1;
  content: "";
  border: 5px solid #8f4e4e;
  display: block;
  position: absolute;
}

.ribbon span {
  width: 225px;
  color: #fff;
  text-shadow: 0 1px 1px #0003;
  text-transform: uppercase;
  text-align: center;
  background-color: #c91818;
  padding: 15px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  display: block;
  position: absolute;
  box-shadow: 0 5px 10px #0000001a;
}

.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left:before, .ribbon-top-left:after {
  border-top-color: #0000;
  border-left-color: #0000;
}

.ribbon-top-left:before {
  top: 0;
  right: 0;
}

.ribbon-top-left:after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  top: 30px;
  right: -25px;
  transform: rotate(-45deg);
}

html, body {
  height: 100%;
  width: 100%;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  margin: 0;
  padding: 0;
  font-family: Montserrat, arial, sans-serif;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.placeholder\:text-base::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-xs::placeholder {
  font-size: .75rem;
  line-height: 1rem;
}

.placeholder\:font-light::placeholder {
  font-weight: 300;
}

.placeholder\:font-normal::placeholder {
  font-weight: 400;
}

.placeholder\:italic::placeholder {
  font-style: italic;
}

.placeholder\:text-content-light::placeholder {
  --tw-text-opacity: 1;
  color: rgb(187 201 218 / var(--tw-text-opacity));
}

.placeholder\:text-error-light::placeholder {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.before\:mr-\[1em\]:before {
  content: var(--tw-content);
  margin-right: 1em;
}

.before\:h-2:before {
  content: var(--tw-content);
  height: .5rem;
}

.before\:h-2\.5:before {
  content: var(--tw-content);
  height: .625rem;
}

.before\:w-2:before {
  content: var(--tw-content);
  width: .5rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:border:before {
  content: var(--tw-content);
  border-width: 1px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-border-lighter:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.before\:bg-primary-light:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(143 104 252 / var(--tw-bg-opacity));
}

.before\:bg-transparent:before {
  content: var(--tw-content);
  background-color: #0000;
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:-left-\[10px\]:after {
  content: var(--tw-content);
  left: -10px;
}

.after\:-right-8:after {
  content: var(--tw-content);
  right: -2rem;
}

.after\:-top-1\/3:after {
  content: var(--tw-content);
  top: -33.3333%;
}

.after\:-top-8:after {
  content: var(--tw-content);
  top: -2rem;
}

.after\:-top-\[25\%\]:after {
  content: var(--tw-content);
  top: -25%;
}

.after\:-top-\[33\%\]:after {
  content: var(--tw-content);
  top: -33%;
}

.after\:left-1\/2:after, .after\:left-\[50\%\]:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:left-full:after {
  content: var(--tw-content);
  left: 100%;
}

.after\:top-1\/2:after {
  content: var(--tw-content);
  top: 50%;
}

.after\:top-\[-10px\]:after {
  content: var(--tw-content);
  top: -10px;
}

.after\:top-full:after {
  content: var(--tw-content);
  top: 100%;
}

.after\:-ml-\[5px\]:after {
  content: var(--tw-content);
  margin-left: -5px;
}

.after\:-mt-\[5px\]:after {
  content: var(--tw-content);
  margin-top: -5px;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-16:after {
  content: var(--tw-content);
  height: 4rem;
}

.after\:w-16:after {
  content: var(--tw-content);
  width: 4rem;
}

.after\:rounded-\[50\%\]:after {
  content: var(--tw-content);
  border-radius: 50%;
}

.after\:border-4:after {
  content: var(--tw-content);
  border-width: 4px;
}

.after\:border-\[5px\]:after {
  content: var(--tw-content);
  border-width: 5px;
}

.after\:border-solid:after {
  content: var(--tw-content);
  border-style: solid;
}

.after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

.after\:border-b-border-lighter:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.after\:border-b-primary-lighter:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(177 157 255 / var(--tw-border-opacity));
}

.after\:border-b-primary-medium:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.after\:border-l-border-lighter:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.after\:border-r-border-lighter:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-right-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.after\:border-t-border-lighter:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.after\:bg-\[\#53d1a3\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(83 209 163 / var(--tw-bg-opacity));
}

.after\:bg-\[\#e68985\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(230 137 133 / var(--tw-bg-opacity));
}

.after\:bg-transparent:after {
  content: var(--tw-content);
  background-color: #0000;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:mt-4:first-child {
  margin-top: 1rem;
}

.first\:rounded-t-2xl:first-child {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.first\:border-t:first-child {
  border-top-width: 1px;
}

.last\:rounded-b-2xl:last-child {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.last\:border-b:last-child {
  border-bottom-width: 1px;
}

.last\:font-bold:last-child {
  font-weight: 700;
}

.last\:text-primary-darker:last-child {
  --tw-text-opacity: 1;
  color: rgb(38 21 104 / var(--tw-text-opacity));
}

.hover\:h-6:hover {
  height: 1.5rem;
}

.hover\:w-6:hover {
  width: 1.5rem;
}

.hover\:border-border-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(27 40 77 / var(--tw-border-opacity));
}

.hover\:border-border-darker:hover {
  --tw-border-opacity: 1;
  border-color: rgb(18 27 62 / var(--tw-border-opacity));
}

.hover\:border-content-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(45 55 97 / var(--tw-border-opacity));
}

.hover\:border-content-medium:hover {
  --tw-border-opacity: 1;
  border-color: rgb(82 116 164 / var(--tw-border-opacity));
}

.hover\:border-error-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.hover\:border-primary-light:hover {
  --tw-border-opacity: 1;
  border-color: rgb(143 104 252 / var(--tw-border-opacity));
}

.hover\:border-primary-lighter:hover {
  --tw-border-opacity: 1;
  border-color: rgb(177 157 255 / var(--tw-border-opacity));
}

.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.hover\:bg-background-bright:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 253 / var(--tw-bg-opacity));
}

.hover\:bg-background-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 25 58 / var(--tw-bg-opacity));
}

.hover\:bg-background-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 234 243 / var(--tw-bg-opacity));
}

.hover\:bg-background-lightest:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 249 / var(--tw-bg-opacity));
}

.hover\:bg-background-medium:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 34 77 / var(--tw-bg-opacity));
}

.hover\:bg-content-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(45 55 97 / var(--tw-bg-opacity));
}

.hover\:bg-primary-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(143 104 252 / var(--tw-bg-opacity));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-content-bright:hover {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.hover\:text-content-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(45 55 97 / var(--tw-text-opacity));
}

.hover\:text-content-darker:hover {
  --tw-text-opacity: 1;
  color: rgb(9 20 49 / var(--tw-text-opacity));
}

.hover\:text-content-light:hover {
  --tw-text-opacity: 1;
  color: rgb(187 201 218 / var(--tw-text-opacity));
}

.hover\:text-content-medium:hover {
  --tw-text-opacity: 1;
  color: rgb(82 116 164 / var(--tw-text-opacity));
}

.hover\:text-primary-darker:hover {
  --tw-text-opacity: 1;
  color: rgb(38 21 104 / var(--tw-text-opacity));
}

.hover\:text-primary-light:hover {
  --tw-text-opacity: 1;
  color: rgb(143 104 252 / var(--tw-text-opacity));
}

.hover\:text-primary-medium:hover {
  --tw-text-opacity: 1;
  color: rgb(121 66 237 / var(--tw-text-opacity));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-border-darker:focus {
  --tw-border-opacity: 1;
  border-color: rgb(18 27 62 / var(--tw-border-opacity));
}

.focus\:border-error-normal:focus {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group\/tooltip:hover .group-hover\/tooltip\:block, .group:hover .group-hover\:block {
  display: block;
}

.group\/statuses:hover .group-hover\/statuses\:flex {
  display: flex;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:w-\[calc\(100\%-48px\)\] {
  width: calc(100% - 48px);
}

.group\/checkbox:hover .group-hover\/checkbox\:border-border-bright {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.group\/checkbox:hover .group-hover\/checkbox\:border-border-darker, .group:hover .group-hover\:border-border-darker {
  --tw-border-opacity: 1;
  border-color: rgb(18 27 62 / var(--tw-border-opacity));
}

.group:hover .group-hover\:border-error-dark {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-content-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(239 241 246 / var(--tw-bg-opacity));
}

.group\/checkbox:hover .group-hover\/checkbox\:text-content-bright {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.group\/checkbox:hover .group-hover\/checkbox\:text-content-darker {
  --tw-text-opacity: 1;
  color: rgb(9 20 49 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-content-bright {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-content-darker {
  --tw-text-opacity: 1;
  color: rgb(9 20 49 / var(--tw-text-opacity));
}

.dark .dark\:cursor-not-allowed {
  cursor: not-allowed;
}

.dark .dark\:border {
  border-width: 1px;
}

.dark .dark\:border-solid {
  border-style: solid;
}

.dark .dark\:border-background-bright {
  --tw-border-opacity: 1;
  border-color: rgb(248 248 253 / var(--tw-border-opacity));
}

.dark .dark\:border-background-medium {
  --tw-border-opacity: 1;
  border-color: rgb(24 34 77 / var(--tw-border-opacity));
}

.dark .dark\:border-border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(27 40 77 / var(--tw-border-opacity));
}

.dark .dark\:border-border-lighter {
  --tw-border-opacity: 1;
  border-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.dark .dark\:border-border-medium {
  --tw-border-opacity: 1;
  border-color: rgb(45 64 109 / var(--tw-border-opacity));
}

.dark .dark\:border-border-regular {
  --tw-border-opacity: 1;
  border-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.dark .dark\:border-content-bright {
  --tw-border-opacity: 1;
  border-color: rgb(248 247 253 / var(--tw-border-opacity));
}

.dark .dark\:border-content-dark {
  --tw-border-opacity: 1;
  border-color: rgb(45 55 97 / var(--tw-border-opacity));
}

.dark .dark\:border-content-medium {
  --tw-border-opacity: 1;
  border-color: rgb(82 116 164 / var(--tw-border-opacity));
}

.dark .dark\:border-error-darker {
  --tw-border-opacity: 1;
  border-color: rgb(105 41 52 / var(--tw-border-opacity));
}

.dark .dark\:border-primary-dark {
  --tw-border-opacity: 1;
  border-color: rgb(79 36 171 / var(--tw-border-opacity));
}

.dark .dark\:border-primary-darker {
  --tw-border-opacity: 1;
  border-color: rgb(38 21 104 / var(--tw-border-opacity));
}

.dark .dark\:border-primary-light {
  --tw-border-opacity: 1;
  border-color: rgb(143 104 252 / var(--tw-border-opacity));
}

.dark .dark\:border-primary-medium {
  --tw-border-opacity: 1;
  border-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.dark .dark\:border-b-transparent {
  border-bottom-color: #0000;
}

.dark .dark\:bg-background-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 253 / var(--tw-bg-opacity));
}

.dark .dark\:bg-background-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(12 25 58 / var(--tw-bg-opacity));
}

.dark .dark\:bg-background-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(2 13 41 / var(--tw-bg-opacity));
}

.dark .dark\:bg-background-light {
  --tw-bg-opacity: 1;
  background-color: rgb(177 174 193 / var(--tw-bg-opacity));
}

.dark .dark\:bg-background-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(24 34 77 / var(--tw-bg-opacity));
}

.dark .dark\:bg-border-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(27 40 77 / var(--tw-bg-opacity));
}

.dark .dark\:bg-content-bright {
  --tw-bg-opacity: 1;
  background-color: rgb(248 247 253 / var(--tw-bg-opacity));
}

.dark .dark\:bg-content-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(45 55 97 / var(--tw-bg-opacity));
}

.dark .dark\:bg-content-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(82 116 164 / var(--tw-bg-opacity));
}

.dark .dark\:bg-primary-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(38 21 104 / var(--tw-bg-opacity));
}

.dark .dark\:bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(143 104 252 / var(--tw-bg-opacity));
}

.dark .dark\:bg-primary-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(121 66 237 / var(--tw-bg-opacity));
}

.dark .dark\:bg-transparent {
  background-color: #0000;
}

.dark .dark\:stroke-content-lighter {
  stroke: #e4e8ef;
}

.dark .dark\:stroke-content-medium {
  stroke: #5274a4;
}

.dark .dark\:text-background-bright {
  --tw-text-opacity: 1;
  color: rgb(248 248 253 / var(--tw-text-opacity));
}

.dark .dark\:text-background-dark {
  --tw-text-opacity: 1;
  color: rgb(12 25 58 / var(--tw-text-opacity));
}

.dark .dark\:text-content-bright {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.dark .dark\:text-content-dark {
  --tw-text-opacity: 1;
  color: rgb(45 55 97 / var(--tw-text-opacity));
}

.dark .dark\:text-content-darker {
  --tw-text-opacity: 1;
  color: rgb(9 20 49 / var(--tw-text-opacity));
}

.dark .dark\:text-content-light {
  --tw-text-opacity: 1;
  color: rgb(187 201 218 / var(--tw-text-opacity));
}

.dark .dark\:text-content-lighter {
  --tw-text-opacity: 1;
  color: rgb(228 232 239 / var(--tw-text-opacity));
}

.dark .dark\:text-content-medium {
  --tw-text-opacity: 1;
  color: rgb(82 116 164 / var(--tw-text-opacity));
}

.dark .dark\:text-content-regular {
  --tw-text-opacity: 1;
  color: rgb(117 146 189 / var(--tw-text-opacity));
}

.dark .dark\:text-error-normal {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-bright {
  --tw-text-opacity: 1;
  color: rgb(234 229 252 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-dark {
  --tw-text-opacity: 1;
  color: rgb(79 36 171 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-darker {
  --tw-text-opacity: 1;
  color: rgb(38 21 104 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-light {
  --tw-text-opacity: 1;
  color: rgb(143 104 252 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-lighter {
  --tw-text-opacity: 1;
  color: rgb(177 157 255 / var(--tw-text-opacity));
}

.dark .dark\:text-primary-regular {
  --tw-text-opacity: 1;
  color: rgb(131 89 246 / var(--tw-text-opacity));
}

.dark .dark\:shadow-background-darker {
  --tw-shadow-color: #020d29;
  --tw-shadow: var(--tw-shadow-colored);
}

.dark .dark\:placeholder\:text-content-dark::placeholder {
  --tw-text-opacity: 1;
  color: rgb(45 55 97 / var(--tw-text-opacity));
}

.dark .dark\:placeholder\:text-content-regular::placeholder {
  --tw-text-opacity: 1;
  color: rgb(117 146 189 / var(--tw-text-opacity));
}

.dark .dark\:placeholder\:text-error-darker::placeholder {
  --tw-text-opacity: 1;
  color: rgb(105 41 52 / var(--tw-text-opacity));
}

.dark .dark\:placeholder\:text-primary-dark::placeholder {
  --tw-text-opacity: 1;
  color: rgb(79 36 171 / var(--tw-text-opacity));
}

.dark .dark\:before\:border-border-medium:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(45 64 109 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-b-border-regular:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-b-primary-light:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(143 104 252 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-b-primary-medium:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-l-border-regular:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-r-border-regular:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-right-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.dark .dark\:after\:border-t-border-regular:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-color: rgb(72 90 133 / var(--tw-border-opacity));
}

.dark .dark\:last\:text-primary-lighter:last-child {
  --tw-text-opacity: 1;
  color: rgb(177 157 255 / var(--tw-text-opacity));
}

.dark .dark\:hover\:border-border-bright:hover {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.dark .dark\:hover\:border-content-medium:hover {
  --tw-border-opacity: 1;
  border-color: rgb(82 116 164 / var(--tw-border-opacity));
}

.dark .dark\:hover\:border-error-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.dark .dark\:hover\:border-primary-medium:hover {
  --tw-border-opacity: 1;
  border-color: rgb(121 66 237 / var(--tw-border-opacity));
}

.dark .dark\:hover\:bg-background-medium:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 34 77 / var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-content-lighter:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(228 232 239 / var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-primary-medium:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(121 66 237 / var(--tw-bg-opacity));
}

.dark .dark\:hover\:text-content-bright:hover {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

.dark .dark\:hover\:text-content-light:hover {
  --tw-text-opacity: 1;
  color: rgb(187 201 218 / var(--tw-text-opacity));
}

.dark .dark\:hover\:text-primary-lighter:hover {
  --tw-text-opacity: 1;
  color: rgb(177 157 255 / var(--tw-text-opacity));
}

.dark .dark\:hover\:text-primary-medium:hover {
  --tw-text-opacity: 1;
  color: rgb(121 66 237 / var(--tw-text-opacity));
}

.dark .dark\:focus\:border-border-bright:focus {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.dark .dark\:focus\:border-border-lighter:focus {
  --tw-border-opacity: 1;
  border-color: rgb(204 208 227 / var(--tw-border-opacity));
}

.dark .group\/checkbox:hover .dark\:group-hover\/checkbox\:border-border-bright, .dark .group:hover .dark\:group-hover\:border-border-bright {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.dark .group:hover .dark\:group-hover\:border-error-dark {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.dark .group\/checkbox:hover .dark\:group-hover\/checkbox\:text-content-bright, .dark .group:hover .dark\:group-hover\:text-content-bright {
  --tw-text-opacity: 1;
  color: rgb(248 247 253 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:p-12 {
    padding: 3rem;
  }
}

/*# sourceMappingURL=index.fc1d7507.css.map */
