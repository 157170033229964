a,
a:hover {
  color: unset;
}

.companyLogo {
  max-width: 100px;
  max-height: 60px;
}

.locked,
.blacklisted {
  box-shadow: inset 5px 0px 0 0px rgba(196, 32, 32, 0.5);
}

.CandidateLink {
  color: #1890ff;
}

&::-webkit-scrollbar {
  background-color: transparent;

  &:vertical {
    width: 4px;
  }

  &:horizontal {
    height: 4px;
  }

  &-corner {
    background-color: transparent;
  }

  &-thumb {
    border-radius: 1em;
    background-color: #d4d4d4;
  }
}
