.userStatus {
  .notif {
    a {
      display: inline;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    span {
      font-weight: bold;
      font-size: 20px;
      margin-right: 8px;
      color: #1890ff;
    }
  }

  .assignation {
    a {
      color: #1890ff;
      margin: 0 5px;
    }
  }
}
