.login {
  background-color: #2c76ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%231508ff' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%232c76ff' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%232c71f8' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%232c6cf1' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%232c67ea' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%232c62e3' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%232c5ddc' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%232b58d5' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%232a53ce' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23294ec7' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23284ac0' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%232745b9' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%232540b3' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23243cac' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%232237a5' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%2320339f' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%231e2e98' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%231c2991' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%231a258b' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
