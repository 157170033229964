// TODO CHECK
@media print {
  .dashboardHeader {
    display: none;
  }

  .dashboardModule {
    page-break-before: auto;
  }
}

.supersetDashboard {
  height: 100vh;
  width: 100%;

  iframe {
    height: 100vh;
    width: 100%;
  }
}
