.loaderPage {
  text-align: center;
  height: 100%;
  background: #999
    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9ImNpcmNsZSIgY3k9ImZhcnRoZXN0LXNpZGUiIHI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzMzMzMzMiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxMTExMTEiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=");
  background: #999 radial-gradient(circle farthest-side, #333333, #111111);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.33333em;
  height: 7.33333em;
  margin-left: -3.66667em;
  margin-top: -3.66667em;
}

.loader-block {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  opacity: 0;
  width: 2em;
  height: 2em;
  background: #fdfdfd;
  animation: show 0.4s step-end infinite alternate,
    pulse 0.4s linear infinite alternate;
}

.loader-block:nth-child(1) {
  transform: translate(0, 0);
  animation-delay: 0.03s;
}

.loader-block:nth-child(2) {
  transform: translate(2.66667em, 0);
  animation-delay: 0.06s;
}

.loader-block:nth-child(3) {
  transform: translate(5.33333em, 0);
  animation-delay: 0.09s;
}

.loader-block:nth-child(4) {
  transform: translate(0, 2.66667em);
  animation-delay: 0.15s;
}

.loader-block:nth-child(5) {
  transform: translate(2.66667em, 2.66667em);
  animation-delay: 0.06s;
}

.loader-block:nth-child(6) {
  transform: translate(5.33333em, 2.66667em);
  animation-delay: 0.21s;
}

.loader-block:nth-child(7) {
  transform: translate(0, 5.33333em);
  animation-delay: 0.18s;
}

.loader-block:nth-child(8) {
  transform: translate(2.66667em, 5.33333em);
  animation-delay: 0.12s;
}

.loader-block:nth-child(9) {
  transform: translate(5.33333em, 5.33333em);
}

@-webkit-keyframes pulse {
  from,
  40% {
    background: #fdfdfd;
  }
  to {
    background: #dadada;
  }
}
@-webkit-keyframes show {
  from,
  40% {
    opacity: 0;
  }
  41%,
  to {
    opacity: 1;
  }
}
@keyframes pulse {
  from,
  40% {
    background: #fdfdfd;
  }
  to {
    background: #dadada;
  }
}
@keyframes show {
  from,
  40% {
    opacity: 0;
  }
  41%,
  to {
    opacity: 1;
  }
}
